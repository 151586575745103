
import { IMemberAccountDropdownState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseAction, SelectAccountAction } from './actions';

export const state: IMemberAccountDropdownState = {
  accounts: [],
  selectedAccount: null
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      accounts: payload,
      // set the selected account if we dont have it persisted in state, otherwise set it on initial 
      selectedAccount: !state.selectedAccount ? payload[0] : state.selectedAccount
    };
  }),
  on(SelectAccountAction, (state, { payload }) => {
    return {
      ...state,
      selectedAccount: payload
    };
  })
);

export function memberAccountDropdownReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
