import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { commomState_loginViewVideoUrl } from 'src/app/store/common/common.selectors';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorPageComponent extends ComponentBase implements OnInit, OnDestroy {

  loginViewVideoUrl$ = this.store.pipe(select(commomState_loginViewVideoUrl));
  videoUrl: SafeUrl = '';

  constructor(public store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private msalService: MsalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    localStorage.removeItem('login-error');

    this.sub = this.loginViewVideoUrl$.subscribe(x => {
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(x)
    });
  }

  onSubmitClick() {
    this.msalService.logoutRedirect();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
