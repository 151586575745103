import { historicalChartView_Model, historicalChartView_SelectedFilter } from './../selectors';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, SelectFilterAction } from './../actions';
import { ComponentBase } from '../../../../../views/component-base';
import { AppState } from '../../../../../store/app.states';
import { MemberPortalSharedState } from '../../../store/shared.states';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexTooltip,
  ApexFill,
  ApexYAxis,
  ApexMarkers
} from "ng-apexcharts";
import { DatePipe } from '@angular/common';
import { memberAccountDropdown_SelectedAccount } from '../../member-account-dropdown/selectors';
import { debounceTime, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { HistoricalGraphItemModel } from 'src/app/model/historical-graph.model';
import { TranslateService } from '@ngx-translate/core';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';
import { commomState_ScreenWidth, commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { Helper } from '@ifaa-components/ui-components';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  colors: string[];
  fill: ApexFill;
  markers: ApexMarkers;
};

@Component({
  selector: 'app-historical-chart-view',
  templateUrl: './historical-chart-view.component.html',
  styleUrls: ['./historical-chart-view.component.scss']
})
export class HistoricalChartViewComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  pipe = new DatePipe('en-AU');
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("chart2") chart2: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  model$ = this.store.pipe(select(historicalChartView_Model));
  selectedFilter$ = this.store.pipe(select(historicalChartView_SelectedFilter));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  accountFeatureToggles$ = this.appStore.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  helper = new Helper();

  viewTransactionsFeatureToggle = FeatureToggleName.member.account.transactions.view;
  viewOtherStatementsFeatureToggle = FeatureToggleName.member.account.viewStatements.view;
  lodgeCirtClaimFeatureToggle = FeatureToggleName.member.account.lodgeCirtClaim.view;
  jetcoClaimFeatureToggle = FeatureToggleName.member.account.JetcoClaim.view;

  featureToggleHelper = new FeatureToggleHelper();
  currentFilter: string = '';
  data: HistoricalGraphItemModel[] = [];
  public updateOptionsData = {
    "1m": {
      xaxis: {
        min: this.subtractDate(1).getTime(),
        max: new Date().getTime()
      }
    },
    "6m": {
      xaxis: {
        min: this.subtractDate(6).getTime(),
        max: new Date().getTime()
      }
    },
    "1y": {
      xaxis: {
        min: this.subtractDate(12).getTime(),
        max: new Date().getTime()
      }
    },

    all: {
      xaxis: {
        min: undefined,
        max: undefined
      }
    }
  };

  public chartOptions2: Partial<ChartOptions>;
  constructor(public store: Store<MemberPortalSharedState>,
    public appStore: Store<AppState>,
    public translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    super();


    this.translate.get('HISTORICAL_CHART_VIEW')
      .subscribe(chart => {
        this.chartOptions = {
          tooltip: {
            enabled: true,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return '<div class="arrow_box p-3 chart_infobox" style="min-width: 150px;">' +
                '<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Date:</div> ' + this.pipe.transform(this.data[dataPointIndex].x, 'dd/MM/yyyy') + '</div></div>' +
                '<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Amount:</div> $' + series[seriesIndex][dataPointIndex] + '</div></div>' +
                `<div class="${chart.DISPLAY_UNITS ? 'd-block' : 'd-none'}"> <div class="d-flex flex-row"><div style="width: 60px">Units:</div> ` + this.data[dataPointIndex].unit + '</div></div>' +
                '</div>'
            }
          },
          series: [],

          chart: {
            redrawOnWindowResize: true,
            height: 350,
            type: chart.CHARTTYPE,
            id: 'historicalchart',
            selection: {

            },
            zoom: {
              type: "x",
              enabled: false,
              autoScaleYaxis: true
            },

            events: {
              click: function (e, chart, options) {
                var tt = '';
              },
              dataPointSelection: function (e, chart, options) {
              }
            },
          },
          markers: {
            colors: [chart.CHARTMARKERCOLOR],
            strokeColors: chart.CHARTMARKERBORDERCOLOR,
          },
          colors: [chart.CHARTLINECOLOR],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          title: {
            text: "",
            align: "left"
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.1
            }
          },
          yaxis: {
            tickAmount: 6,
            labels: {
              formatter: function (value) {
                return "$" + Number(value).toFixed(2);;
              }
            },
          },
          xaxis: {
            type: 'datetime',

            tooltip: {
              formatter: (value) => {
                return this.pipe.transform(new Date(value).toISOString(), 'dd/MM');
              }
            },

          },
        }
        var minDate = moment().subtract(20, 'days').valueOf() as number;
        var maxDate = moment().valueOf() as number;

        this.chartOptions2 = {
          tooltip: {
            enabled: false,
          },
          series: [],

          chart: {
            redrawOnWindowResize: true,
            height: 120,
            type: chart.CHARTTYPE,
            id: 'historicalchart1',
            selection: {
              enabled: true,
              xaxis: {
                min: minDate,
                max: maxDate,
              }
            },
            brush: {
              target: "historicalchart",
              enabled: true
            },
            zoom: {
              enabled: false,
            },

            events: {
              click: function (e, chart, options) {
                var tt = '';
              },
              dataPointSelection: function (e, chart, options) {
              }
            },
          },

          markers: {
            colors: [chart.CHARTMARKERCOLOR],
            strokeColors: chart.CHARTMARKERBORDERCOLOR,
          },
          colors: [chart.CHARTLINECOLOR],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          title: {
            text: "",
            align: "left"
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.1
            }
          },
          fill: {

          },
          yaxis: {
            show: false,
            labels: {
              formatter: function (value) {
                return "$" + Number(value).toFixed(2);;
              }
            },
          },
          xaxis: {
            type: 'datetime',

            tooltip: {
              formatter: (value) => {
                return this.pipe.transform(new Date(value).toISOString(), 'dd/MM');
              }
            },

          },
        }

      });

  }
  ngAfterViewInit() {

  }
  subtractDate(months: number): Date {
    var d = new Date();
    d.setMonth(d.getMonth() - months);
    return d;
  }
  lastSearch = {
    accountId: 0,
    from: null,
    to: null
  }
  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.appStore
      .pipe(
        debounceTime(500),
        select(commomState_ScreenWidth)).subscribe(async x => {
          var filter = await this.helper.getValue(this.selectedFilter$);
          if (this.chart)
            this.chart.updateOptions(this.updateOptionsData[filter], false, true, true);

          if (this.chart2)
            this.chart2.updateOptions(this.updateOptionsData[filter], false, true, true);
        });

    this.sub = combineLatest([this.selectedAccount$, this.selectedFilter$])
      .pipe(
        map(([account, filter]) => ({ account, filter })),
      ).subscribe(async x => {
        if (x.account && x.filter) {

          this.currentFilter = x.filter;
          if (this.chart)
            this.chart.updateOptions(this.updateOptionsData[x.filter], false, true, true);

          if (this.chart2) {
            this.chart2.updateOptions(this.updateOptionsData[x.filter], false, true, true);



          }

          var nextSearch = {
            accountId: x.account.accountId,
            from: this.updateOptionsData[x.filter].xaxis.min,
            to: this.updateOptionsData[x.filter].xaxis.max
          };
          if (JSON.stringify(this.lastSearch) != JSON.stringify(nextSearch)) {
            this.lastSearch = nextSearch;
            this.dispatch(this.store, RequestAction(nextSearch));
          }
        }
      });

    this.sub = this.model$.subscribe(model => {
      if (model.data.length > 0) {
        this.data = model.data;


        if (this.chart)
          this.chart.updateSeries([{
            data: model.data
          }])

        if (this.chart2) {
          var days = this.screenWidth < 800 ? 60 : 90;
          if (this.currentFilter == '1m') {
            days = 20;
          }
          var minDate = moment().subtract(days, 'days').valueOf() as number;
          var maxDate = moment().valueOf() as number;

          this.chart2.updateOptions({
            chart: {
              selection: {
                enabled: true,
                xaxis: {
                  min: minDate,
                  max: maxDate,
                }
              },
            },
          });
          this.chart2.updateSeries([{
            data: model.data
          }])

        }
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  public updateOptions(option: any): void {
    this.store.dispatch(SelectFilterAction({ payload: option }))
  }
}

