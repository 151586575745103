export class HistoricalGraphModel {
  graphDescription: string = '';
  totalAmount: number = 0;
  asAt: Date = null;
  data: HistoricalGraphItemModel[] = [];
  pendingAmount: number = 0;
}


export class HistoricalGraphItemModel {
  x: Date = null;
  y: number = null;
  unit: number = null;
}
