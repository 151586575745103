<footer id="footer-layout-container" *ngIf="tenantConfig$ | async; let tenant">

  <div class="container disclaimer d-flex ">

    <div class="flex-fill text">
      <p class="d-flex flex-row button-row">

        <a class="btn btn-primary mr-3" href="https://www.futuresuper.com.au/contact-us/" target="_blank">Contact us
        </a>
        <!-- <a class="btn btn-primary" href="https://form.asana.com/?k=sYyT-VjSZSLHHvl037e-kw&d=15281311455590"
          target="_blank">Leave feedback
        </a> -->
      </p>
      <p>Information provided is of a general nature only and we have not taken your personal financial objectives,
        situation or needs into account. You should consider whether Future Super’s products are right for your
        individual objectives and needs and consider seeking personal financial advice. Before making a decision to
        acquire, hold or continue to hold an interest in Future Super, please read the Product Disclosure Statement
        (PDS), Financial Services Guide (FSG) and check our Target Market Determination (TMD) available at <a
          href="https://www.futuresuper.com.au/documents-and-forms/" target="_blank">Documents and
          Forms | Future Super</a>. Future Super does not accept any responsibility for any loss or damage that may
        result
        from reliance on, or the use of, any information contained on this site. The contents of this website are
        exclusively owned by Future Super. You must not use or disclose them for any other reason than for the purposes
        for which they were supplied.
      </p>
      <br />
      <p>
        The Promoter of the Fund is Future Super Services Pty Ltd (ABN 88 652 577 930; AFS Representative No.
        001312077), which is a Corporate Authorised Representative of Future Group Financial Services Pty Ltd (ABN 90
        167 800 580; AFSL 482684).
      </p>
      <br />
      <p>
        Equity Trustees Superannuation Limited (ABN 50 055 641 757, RSE Licence L0001458, AFSL 229757) is Trustee of the
        Future Super Fund (ABN 45 960 194 277; RSE Registration R1072914). The Trustee does not in any way endorse,
        warrant or accept responsibility for any services provided by the Promoter in its own right or directly to
        members or prospective members. Learn more about our Trustee including their Privacy Statement <a
          href="https://www.eqt.com.au/superannuation/board-and-governance" target="_blank">here</a>. The Fund is
        administered by APEX Superannuation (Australia) Pty Ltd (ABN 28 081 966 243; AFSL 238507). Insurance cover is
        provided to eligible members by AIA Australia Limited (ABN 79 004 837 861; AFSL 230043). The Investment Manager
        is Future Group Investment Management Pty Ltd (ABN 55 321 040 702; AFS Representative No. 001271441) which is a
        Corporate Authorised Representative of Future Group Financial Services Pty Ltd (ABN 90 167 800 580; AFSL
        482684).
      </p>
      <br />
      <p>
        When considering a new fund, you should consider checking the costs and amount of any cover offered and any
        relevant information before you make a decision to transfer your super, such as differences in insurance cover
        and fees.
      </p>

    </div>


  </div>
  <div class="d-flex {{screenWidth>992?'flex-row':'flex-column'}} w-100 container mt-4">
    <p class="flex-fill"><a target="_blank" href="https://www.futuresuper.com.au/fund-information"
        class="svelte-ooulvi">Fund Information</a>
      <span class="dot svelte-ooulvi">&nbsp;&nbsp;•&nbsp;&nbsp;</span> <a target="_blank"
        href="https://www.futuresuper.com.au/terms-and-conditions" class="svelte-ooulvi">Terms &amp; Conditions</a>
      <span class="dot svelte-ooulvi">&nbsp;&nbsp;•&nbsp;&nbsp;</span> <a target="_blank"
        href="https://www.futuresuper.com.au/privacy-policy" class="svelte-ooulvi">Privacy Policy</a> <span
        class="dot svelte-ooulvi">&nbsp;&nbsp;•&nbsp;&nbsp;</span> <a target="_blank"
        href="https://www.futuresuper.com.au/complaints" class="svelte-ooulvi">Complaints</a>
    </p>
    <div class="d-flex flex-row">
      <p class="svelte-ooulvi"><span class="bold">ABN</span> ‍45 ‍960 ‍194 ‍277
        <span class="dot svelte-ooulvi">&nbsp;&nbsp;</span>
      </p>
      <p class="svelte-ooulvi"><span class="bold">USI</span>‍ 45 ‍960 ‍194 ‍277 ‍010</p>
    </div>
  </div>
</footer>