<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column flex-fill" slot="start">
        <div *ngIf="limitedServiceToggles?.length > 0">
          <app-limited-service-banner [toggles]="limitedServiceToggles"></app-limited-service-banner>
        </div>
        <div class="d-flex flex-row justify-content-between my-other-account">

          <div class="page-title">Dashboard</div>

        </div>
      </div>

    </app-toolbar-options>
  </div>

  <div class="page-content" *ngIf="!(selectedAccount$ | async)">
    <mat-progress-bar class="" mode="indeterminate">
    </mat-progress-bar>
  </div>
  <div class="page-content" appanimatecss *ngIf="selectedAccount$ | async">
    <div class="d-flex align-items-stretch widgets-row-low">
      <div class="first-row d-flex {{screenWidth>992?'flex-row':'flex-column'}} w-100">
        <app-member-view-overview *featuretoggle="memberProfileSummaryFeatureToggle"
          class="d-flex flex-fill widget-1 w-50">
        </app-member-view-overview>

        <app-component-wrapper icon="badge" header="Recent employment details"
          *featuretoggle="recentEmployerDetailsFeatureToggle" class="recent-employment flex-fill widget-1 d-flex w-50">
          <div class="d-flex flex-column" slot="start">

            <app-recent-employment-details class="recent-employer-details flex-fill">
            </app-recent-employment-details>

          </div>
          <div class="d-flex flex-row components-actions" slot="actions">
            <app-button *featuretoggle="viewTransactionsFeatureToggle" icon="" routerLink="/transaction-listing"
              title="View all transactions"></app-button>
          </div>
        </app-component-wrapper>


        <!-- <app-account-summary *featuretoggle="accountSummaryFeatureToggle" class=" d-flex flex-fill widget-1 w-50"
          [ngClass]="{'ml-3': addSpacingForWidget && screenWidth>992}"></app-account-summary>
        <app-defined-benefit-account-summary *featuretoggle="dbAccountSummaryFeatureToggle"
          class=" d-flex flex-fill widget-1 w-50" [ngClass]="{'ml-3': addSpacingForWidget && screenWidth>992}">
        </app-defined-benefit-account-summary> -->
      </div>
    </div>



    <app-component-wrapper class="historical-graph-component" icon="" header=""
      *featuretoggle="accountBalWithGraphFeatureToggle">

      <div class="d-flex flex-column" slot="start">
        <app-historical-chart-view></app-historical-chart-view>
      </div>
    </app-component-wrapper>

    <app-component-wrapper class="historical-graph-component" icon="" header=""
      *featuretoggle="accountBalWithoutGraphFeatureToggle">

      <div class="d-flex flex-column" slot="start">
        <app-account-bal-without-graph></app-account-bal-without-graph>
      </div>
    </app-component-wrapper>

    <app-component-wrapper id="app-investment-edit-wrapper" icon="price_change" header="Investment Options"
      *featuretoggle="manageInvestmentsFeatureToggle">
      <div class="d-flex flex-column" slot="start">

        <app-investment-edit [showOptions]="false" [isDashboardView]="true"></app-investment-edit>
        <div class="d-flex flex-row components-actions investment-edit" *featuretoggle="updateInvestmentsFeatureToggle">
          <app-button loading title="Manage Investments" icon="edit" *ngIf="!isPensionAccount" routerLink="/investment">
          </app-button>
        </div>
      </div>
    </app-component-wrapper>

    <div class="d-flex align-items-stretch">
      <app-component-wrapper id="app-consolidate-super-wrapper" icon="merge_type" header="consolidate your super"
        *featuretoggle="rollInFeatureToggle" class="consolidatesuper flex-fill d-flex"
        [ngClass]="{'mr-2 width0': screenWidth>992}">
        <div class="d-flex flex-column" slot="start">

          <app-consolidate-super class="w-100"></app-consolidate-super>

        </div>
        <div class="d-flex flex-row components-actions" slot="actions">
          <app-button icon="{{'BTN.consolidate-super.start.icon' | translate}}"
            title="{{'BTN.consolidate-super.start.title' | translate}}" (onClick)="onStartClick()">
          </app-button>
        </div>
      </app-component-wrapper>

      <app-component-wrapper id="app-tell-your-employer-wrapper" icon="" header="Tell your employer"
        *featuretoggle="rollInFeatureToggle" class="consolidatesuper flex-fill d-flex"
        [ngClass]="{'ml-2 width0': screenWidth>992}">
        <div class="d-flex flex-column flex-fill" slot="start">

          <p class="description">
            Have you recently moved jobs?
          </p>
          <p class="description">Click below to get the details needed to make sure they start paying your super. </p>

        </div>
        <div class="d-flex flex-row components-actions" slot="actions">
          <app-button title="Tell my employer" (onClick)="onTellMyEmployerClick()">
          </app-button>
        </div>
      </app-component-wrapper>

      <!-- <app-component-wrapper icon="badge" header="Recent employment details"
        *featuretoggle="recentEmployerDetailsFeatureToggle" class="recent-employment flex-fill d-flex"
        [ngClass]="{'ml-2 width0': screenWidth>992}">
        <div class="d-flex flex-column" slot="start">

          <app-recent-employment-details class="recent-employer-details flex-fill">
          </app-recent-employment-details>

        </div>
      </app-component-wrapper> -->






    </div>

    <section class="svelte-1apy5p mt-4" *ngIf="screenWidth>992">
      <div class="bg-outer svelte-1apy5p"></div>
      <div class="bg svelte-1apy5p"></div>
      <div class="content-wrapper">
        <div class="text-and-links svelte-1apy5p">
          <h4>Get the app</h4>
          <p class="svelte-1apy5p">Get easy access to your account by downloading the Future Super App today!</p>
          <div class="links svelte-1apy5p">
            <div class="buttons">
              <div class="app-store-button svelte-1apy5p" role="button" aria-label="Download on the Apple App Store">
                <a href="https://apps.apple.com/au/app/future-super/id1620110464" target="_blank">
                  <img id="app-store" loading="lazy"
                    src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/App Download - Apple.png"
                    loading="lazy" alt="Download on the App Store" class="svelte-1apy5p"></a>
              </div>
              <div class="app-store-button svelte-1apy5p" role="button" aria-label="Download on the Google Play Store">
                <a href="https://play.google.com/store/apps/details?id=au.com.futuresuper.app&pli=1" target="_blank">
                  <img id="google-play" loading="lazy"
                    src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/App Download - Google.png"
                    alt="Get it on Google Play" class="svelte-1apy5p">
                </a>
              </div>
            </div> <img id="qr-code" src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/code.png"
              loading="lazy" alt="QR code to download Future Super app on the App Store" class="svelte-1apy5p">
          </div>
        </div>
        <div id="app-crop" class="svelte-1apy5p"><img id="app-screenshot" loading="lazy"
            src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/app-screenshot-3.png"
            alt="Screenshot of the Future Super mobile app" class="svelte-1apy5p"></div>
      </div>
    </section>
    <section class="svelte-1apy5p mobile mt-4" *ngIf="screenWidth<=992">
      <div class="bg-outer svelte-1apy5p"></div>
      <div class="bg svelte-1apy5p"></div>
      <div class="content-wrapper">
        <div class="text-and-links svelte-1apy5p">
          <h4>Get the app</h4>
          <p class="svelte-1apy5p">Get easy access to your account by downloading the Future Super App today!</p>
          <div id="app-crop" class="svelte-1apy5p"><img id="app-screenshot"
              src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/app-screenshot-3.png"
              alt="Screenshot of the Future Super mobile app" class="svelte-1apy5p"></div>
          <div class="links svelte-1apy5p">
            <div class="buttons">
              <div class="app-store-button svelte-1apy5p" role="button" aria-label="Download on the Apple App Store">
                <a href="https://apps.apple.com/au/app/future-super/id1620110464" target="_blank">
                  <img id="app-store"
                    src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/App Download - Apple.png"
                    alt="Download on the App Store" class="svelte-1apy5p"></a>
              </div>
              <div class="app-store-button svelte-1apy5p" role="button" aria-label="Download on the Google Play Store">
                <a href="https://play.google.com/store/apps/details?id=au.com.futuresuper.app&pli=1" target="_blank">
                  <img id="google-play"
                    src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/App Download - Google.png"
                    alt="Get it on Google Play" class="svelte-1apy5p">
                </a>
              </div>
            </div>
            <!-- <img id="qr-code" src="https://sharedassetstorage.blob.core.windows.net/web/FUTR/code.png"
              alt="QR code to download Future Super app on the App Store" class="svelte-1apy5p"> -->
          </div>
        </div>

      </div>
    </section>

  </div>
</div>