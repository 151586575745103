<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column flex-fill" slot="start">
        <div *ngIf="limitedServiceToggles?.length > 0">
          <app-limited-service-banner [toggles]="limitedServiceToggles"></app-limited-service-banner>
        </div>
        <div class="d-flex flex-row justify-content-between my-other-account">

          <div class="page-title">Overview</div>
        </div>
      </div>

    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-member-alert-view></app-member-alert-view>

    <div class="d-flex align-items-stretch widgets-row-low">
      <div class="first-row d-flex {{screenWidth>992?'flex-row':'flex-column'}} w-100">
        <app-member-view-overview *featuretoggle="memberProfileSummaryFeatureToggle"
          class="d-flex flex-fill widget-1 w-50">
        </app-member-view-overview>

        <app-component-wrapper *featuretoggle="accountFeesFeatureToggle" class="member-profile-component w-50 flex-fill"
          icon="perm_contact_calendar" header="Your Account" [ngClass]="{'pl-3': screenWidth>992}">

          <div class="d-flex flex-column" slot="start">
            <app-account-fees class=" d-flex flex-fill widget-1 "></app-account-fees>
          </div>
        </app-component-wrapper>




        <app-account-summary *featuretoggle="accountSummaryFeatureToggle" class=" d-flex flex-fill widget-1 w-50 "
          [ngClass]="{'ml-3': addSpacingForWidget && screenWidth>992}"></app-account-summary>
        <app-defined-benefit-account-summary *featuretoggle="dbAccountSummaryFeatureToggle"
          class=" d-flex flex-fill widget-1 w-50" [ngClass]="{'ml-3': addSpacingForWidget && screenWidth>992}">
        </app-defined-benefit-account-summary>
      </div>
    </div>



    <app-component-wrapper class="historical-graph-component" icon="" header=""
      *featuretoggle="accountBalWithGraphFeatureToggle">

      <div class="d-flex flex-column" slot="start">
        <app-historical-chart-view></app-historical-chart-view>
      </div>
    </app-component-wrapper>

    <app-component-wrapper class="historical-graph-component" icon="" header=""
      *featuretoggle="accountBalWithoutGraphFeatureToggle">

      <div class="d-flex flex-column" slot="start">
        <app-account-bal-without-graph></app-account-bal-without-graph>
      </div>
    </app-component-wrapper>

    <app-component-wrapper icon="price_change" header="Investment Options"
      *featuretoggle="manageInvestmentsFeatureToggle">
      <div class="d-flex flex-column" slot="start">

        <app-investment-edit [showOptions]="false" [isDashboardView]="true"></app-investment-edit>
        <div class="d-flex flex-row components-actions investment-edit" *featuretoggle="updateInvestmentsFeatureToggle">
          <app-button loading title="Manage Investments" icon="{{'BTN.beneficiary-view.edit.icon' | translate}}"
            routerLink="/investment">
          </app-button>
        </div>
      </div>
    </app-component-wrapper>

    <div class="d-flex align-items-stretch">
      <app-component-wrapper icon="badge" header="Recent employment details"
        *featuretoggle="recentEmployerDetailsFeatureToggle" class="recent-employment flex-fill d-flex"
        [ngClass]="{'mr-2 width0': screenWidth>992}">
        <div class="d-flex flex-column" slot="start">

          <app-recent-employment-details class="recent-employer-details flex-fill">
          </app-recent-employment-details>

        </div>
      </app-component-wrapper>

      <app-component-wrapper icon="merge_type" header="consolidate your super" *featuretoggle="rollInFeatureToggle"
        class="consolidatesuper flex-fill d-flex" [ngClass]="{'ml-2 width0': screenWidth>992}">
        <div class="d-flex flex-column" slot="start">

          <app-consolidate-super class="w-100"></app-consolidate-super>

        </div>
        <div class="d-flex flex-row components-actions" slot="actions">
          <app-button icon="{{'BTN.consolidate-super.start.icon' | translate}}" title="Start"
            (onClick)="onStartClick()">
          </app-button>
        </div>
      </app-component-wrapper>




    </div>



  </div>
</div>