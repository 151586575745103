import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IsLoggedInRequestAction } from 'src/app/store/common/common.actions';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-elevated-access',
  templateUrl: './elevated-access.component.html',
  styleUrls: ['./elevated-access.component.scss']
})
export class ElevatedAccessComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>, public router: Router, private msalService: MsalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    sessionStorage.setItem('login-type', 'Elevated');

    // if we are in an iframe, send the isLoggedInRequestAction 
    if (window.self !== window.top) {
      this.store.dispatch(IsLoggedInRequestAction());
    }

    // always redirect to dashboard if trying to get to this page
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
