import { InputScrollDirective, InputAddressScrollDirective, InputDateScrollDirective } from './directives/input-scroll.directive';
import { UnitPricesViewComponent } from './components/unit-prices-view/unit-prices-view.component';
import { MemberDocumentViewerViewComponent } from './components/document-viewer/document-viewer-view.component';
import { UnitPricesViewEffects } from './components/unit-prices-view/effects';
import { DocumentViewerEffects } from './components/document-viewer/effects';
import { NotificationDropdownEffects } from './components/notification-dropdown/effects';
import { ConsolidateSuperEffects } from './components/consolidate-super/effects';
import { ScreenWidthDirective } from './directives/screen-width.directive';
import { PersonalContributionEditEffects } from './components/personal-contribution-edit/effects';
import { ContributionsLimitsEffects } from './components/contributions-limits/effects';
import { ContributionsLimitsComponent } from './components/contributions-limits/contributions-limits.component';
import { SuperannuationProviderSearchComponent } from './components/superannuation-provider-search/superannuation-provider-search.component';
import { PersonalContributionEditComponent } from './components/personal-contribution-edit/personal-contribution-edit.component';
import { RollInEditEffects } from './components/rollin-edit/effects';
import { RollinEditComponent } from './components/rollin-edit/rollin-edit.component';
import { MessagePipe } from './pipes/message';
import { MemberViewOverviewEffects } from './components/member-view-overview/effects';
import { SwitchInvestmentsEffects } from './components/switch-investments-edit/effects';
import { SwitchInvestmentsEditComponent } from './components/switch-investments-edit/switch-investments-edit.component';
import { InsuranceDetailsEffects } from './components/insurance-details/effects';
import { InsuranceDetailsComponent } from './components/insurance-details/insurance-details.component';
import { HistoricalChartViewEffects } from './components/historical-chart/effects';
import { AppRadioDirective } from './directives/app-radio.directive';
import { InsuranceQuestionnaireStartComponent } from './components/insurance-questionnaire-start/insurance-questionnaire-start.component';
import { InsuranceQuestionnaireEffects } from './components/insurance-questionnaire/effects';
import { InsuranceQuestionnaireStartEffects } from './components/insurance-questionnaire-start/effects';
import { InsuranceQuestionnaireComponent } from './components/insurance-questionnaire/insurance-questionnaire.component';
import { MemberAccountDropdownEffects } from './components/member-account-dropdown/effects';
import { CashTransactionsComponent } from './components/cash-transactions/cash-transactions.component';
import { CashTransactionsEffects } from './components/cash-transactions/effects';
import { EditMemberEffects } from './components/member-edit/effects';
import { ConsolidateSuperComponent } from './components/consolidate-super/consolidate-super.component';
import { MemberViewOverviewComponent } from './components/member-view-overview/member-view-overview.component';
import { MemberAccountDropdownComponent } from './components/member-account-dropdown/member-account-dropdown.component';
import { UserTopDropdownComponent } from './components/user-top-dropdown/user-top-dropdown.component';
import { AppButtonDirective } from './directives/appbutton.directive';
import { MaterialModule } from './../../app.material.module';
import { BeneficiariesViewEffects } from './components/beneficiaries-view/effects';
import { SuperannuationProviderSearchEffects } from './components/superannuation-provider-search/effects';
import { BeneficiariesEditEffects } from './components/beneficiaries-edit/effects';
import { AccountUnderlyingViewEffects } from './components/account-underlying-view/effects';
import { MemberEffects } from './components/member-view/effects';
import { MemberViewComponent } from './components/member-view/member-view.component';
import { MemberPensionViewComponent } from './components/member-pension-view/member-pension-view.component';
import { MemberEditComponent } from './components/member-edit/member-edit.component';
import { AccountUnderlyingViewComponent } from './components/account-underlying-view/account-underlying-view.component';
import { BeneficiariesViewComponent } from './components/beneficiaries-view/beneficiaries-view.component';
import { BeneficiariesEditComponent } from './components/beneficiaries-edit/beneficiaries-edit.component';
import { AppComponentWrapperComponent } from './components/app-component-wrapper/app-component-wrapper.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { TenantConfigPipe } from './pipes/tenantconfig';
import { AnimateCssDirective } from './directives/animatecss.directive';
import { FadeInConcatDirective } from './directives/fadein-concat.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { MessageDirective } from './directives/message.directive';
import { PermissionDirective } from './directives/permission.directive';
import { NgrxFormsModule } from 'ngrx-forms';
import { featureKey, localStorageSyncMetaReducer, reducers } from './store/shared.reducers';
import { MemberPortalSharedRoutingModule } from './shared-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BPAYDetailsComponent } from './components/bpay-details/bpay-details.component';
import { BPAYDetailsEffects } from './components/bpay-details/effects';
import { MemberPensionEffects } from './components/member-pension-view/effects';
import { InvestmentEditComponent } from './components/investment-edit/investment-edit.component';
import { EditInvestmentEffects } from './components/investment-edit/effects';
import { AccessYourSuperStartComponent } from './components/access-your-super-start/access-your-super-start.component';
import { AccessYourSuperStartComponentEffects } from './components/access-your-super-start/effects';
import { AccessYourSuperFormComponent } from './components/access-your-super-form/access-your-super-form.component';
import { AccessYourSuperTypeSelectionComponent } from './components/access-your-super-type-selection/access-your-super-type-selection.component';
import { AccessYourSuperTypeSelectionComponentEffects } from './components/access-your-super-type-selection/effects';
import { AccessYourSuperFormComponentEffects } from './components/access-your-super-form/effects';
import { AvailableAccountBalanceComponent } from './components/available-account-balance/available-account-balance.component';
import { AccessYourSuperLastEmployerComponent } from './components/access-your-super-last-employer/access-your-super-last-employer.component';
import { AccessYourSuperLastEmployerComponentEffects } from './components/access-your-super-last-employer/effects';
import { AccessYourSuperRetirementComponent } from './components/access-your-super-custom-sections/access-your-super-retirement/access-your-super-retirement.component';
import { AccessYourSuperRetirementComponentEffects } from './components/access-your-super-custom-sections/access-your-super-retirement/effects';
import { ConvertNanPipe } from './pipes/convertNan';
import { AccessYourSuperDrawdownComponent } from './components/access-your-super-custom-sections/access-your-super-drawdown/access-your-super-drawdown.component';
import { AccessYourSuperCeasedWorkSixtyComponent } from './components/access-your-super-custom-sections/access-your-super-ceased-work-sixty/access-your-super-ceased-work-sixty.component';
import { AccessYourSuperCeasedWorkSixtyComponentEffects } from './components/access-your-super-custom-sections/access-your-super-ceased-work-sixty/effects';
import { AccessYourSuperBankDetailsComponent } from './components/access-your-super-bank-details/access-your-super-bank-details.component';
import { AccessYourSuperBankDetailsComponentEffects } from './components/access-your-super-bank-details/effects';
import { AccountUnderlyingInvestmentSummaryViewComponent } from './components/account-underlying-investment-summary-view/account-underlying-investment-summary-view.component';
import { AccountUnderlyingInvestmentSummaryViewEffects } from './components/account-underlying-investment-summary-view/effects';
import { AccessYourSuperPermanentRetirementPreservationComponent } from './components/access-your-super-custom-sections/access-your-super-permanent-retirement-preservation/access-your-super-permanent-retirement-preservation.component';
import { AccessYourSuperPermanentRetirementPreservationComponentEffects } from './components/access-your-super-custom-sections/access-your-super-permanent-retirement-preservation/effects';
import { AccessYourSuperCeasedPaidEmploymentComponent } from './components/access-your-super-custom-sections/access-your-super-ceased-paid-employment/access-your-super-ceased-paid-employment.component';
import { AccessYourSuperCeasedPaidEmploymentComponentEffects } from './components/access-your-super-custom-sections/access-your-super-ceased-paid-employment/effects';
import { AccessYourSuperPensionCommutationsComponent } from './components/access-your-super-custom-sections/access-your-super-pension-commutations/access-your-super-pension-commutations.component';
import { AccessYourSuperPensionCommutationsComponentEffects } from './components/access-your-super-custom-sections/access-your-super-pension-commutations/effects';
import { AccessYourSuperDocumentsComponent } from './components/access-your-super-documents/access-your-super-documents.component';
import { AccessYourSuperDocumentsComponentEffects } from './components/access-your-super-documents/effects';
import { AccessYourSuperReviewComponent } from './components/access-your-super-review/access-your-super-review.component';
import { AccessYourSuperReviewComponentEffects } from './components/access-your-super-review/effects';
import { LetModule, PushModule } from '@ngrx/component';
import { RecentEmploymentDetailsComponent } from './components/recent-employment-details/recent-employment-details.component';
import { RecentEmploymentDetailsEffects } from './components/recent-employment-details/effects';
import { InvestmentOptionsComponent } from './components/investment-options/investment-options.component';
import { InvestmentOptionsEffects } from './components/investment-options/effects';
import { AccountSummaryComponent } from './components/account-summary/account-summary.component';
import { AccountSummaryEffects } from './components/account-summary/effects';
import { FeatureTogglePipe } from './pipes/featureToggle';
import { LimitedServiceBannerComponent } from './components/limited-service-banner/limited-service-banner.component';
import { AccessYourSuperTerminalMedicalConditionComponent } from './components/access-your-super-custom-sections/access-your-super-terminal-medical-condition/access-your-super-terminal-medical-condition.component';
import { AccessYourSuperTerminalMedicalConditionComponentEffects } from './components/access-your-super-custom-sections/access-your-super-terminal-medical-condition/effects';
import { AccessYourSuperSevereFinancialHardshipComponent } from './components/access-your-super-custom-sections/access-your-super-severe-financial-hardship/access-your-super-severe-financial-hardship.component';
import { AccessYourSuperSevereFinancialHardshipComponentEffects } from './components/access-your-super-custom-sections/access-your-super-severe-financial-hardship/effects';
import { AccessYourSuperCompassionateGroundsComponent } from './components/access-your-super-custom-sections/access-your-super-compassionate-grounds/access-your-super-compassionate-grounds.component';
import { AccessYourSuperCompassionateGroundsComponentEffects } from './components/access-your-super-custom-sections/access-your-super-compassionate-grounds/effects';
import { AccessYourSuperUnrestrictedNonPreservedComponentEffects } from './components/access-your-super-custom-sections/access-your-super-unrestricted-non-preserved/effects';
import { AccessYourSuperUnrestrictedNonPreservedComponent } from './components/access-your-super-custom-sections/access-your-super-unrestricted-non-preserved/access-your-super-unrestricted-non-preserved.component';
import { DefinedBenefitAccountSummaryComponent } from './components/defined-benefit-account-summary/defined-benefit-account-summary.component';
import { AccountBalWithoutGraphComponent } from './components/historical-chart/account-bal-without-graph/account-bal-without-graph.component';
import { HistoricalChartViewComponent } from './components/historical-chart/account-bal-with-graph/historical-chart-view.component';
import { AccessYourSuperKiwiSaverComponent } from './components/access-your-super-custom-sections/access-your-super-kiwi-saver/access-your-super-kiwi-saver.component';
import { AccessYourSuperKiwiSaverComponentEffects } from './components/access-your-super-custom-sections/access-your-super-kiwi-saver/effects';
import { AccessYourSuperStepper } from './components/access-your-super-progress-stepper/access-your-super-progress-stepper.component';
import { InvestmentEditHoverComponent } from './components/investment-edit/investment-edit-hover/investment-edit-hover.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FundComponentLoaderDirective } from './directives/fund-component-loader.directive';
import { NoInsuranceComponent } from './components/no-insurance/no-insurance.component';
import { ElevatedAccessBannerComponent } from './components/elevated-access-banner/elevated-access-banner.component';
import { InvestmentSimpleViewComponent } from './components/investment-edit/investment-simple-view/investment-simple-view.component';
import { InvestmentComplexViewComponent } from './components/investment-edit/investment-complex-view/investment-complex-view.component';
import { InsurancePreQuestionnaireComponent } from './components/insurance-pre-questionnaire/insurance-pre-questionnaire.component';
import { ChatViewEffects } from './components/chat/effects';
import { ChatViewComponent } from './components/chat/chat-view.component';
import { AreYouStillThereDialog } from './components/are-you-still-there-dialog/are-you-still-there.component';
import { AccessYourSuperUpdateSubmittedComponent } from './components/access-your-super-update-submitted/access-your-super-update-submitted.component';
import { AccessYourSuperReviewDisplayComponent } from './components/access-your-super-review-display/access-your-super-review-display.component';
import { AccessYourSuperUpdateSubmittedComponentEffects } from './components/access-your-super-update-submitted/effects';
import { FeatureToggleGlobalDirective } from './directives/feature-toggle-global.directive';
import { TextTransformPipe } from './pipes/textTransform';
import { NoticeOfIntentTaxDeductionContainerComponent } from './components/notice-of-intent-tax-deduction-container/notice-of-intent-tax-deduction-container.component';
import { NoticeOfIntentTaxDeductionContainerComponentEffects } from './components/notice-of-intent-tax-deduction-container/effects';
import { NoticeOfIntentTaxDeductionFormComponent } from './components/notice-of-intent-tax-deduction-form/notice-of-intent-tax-deduction-form.component';
import { NoticeOfIntentTaxDeductionFormComponentEffects } from './components/notice-of-intent-tax-deduction-form/effects';
import { SfhFinancialDetailsV1Component } from './components/access-your-super-custom-sections/access-your-super-severe-financial-hardship/financialDataFormVersions/v1/sfh-financial-details-V1.component';
import { SfhFinancialDetailsReviewV1Component } from './components/access-your-super-review-display/financialDataReviewVersions/v1/severe-financial-hardship-financial-details-review-V1.component';
import { NoiPendingWithdrawalDialog } from './components/notice-of-intent-tax-deduction-form/noi-pending-withdrawal-dialog/noi-pending-withdrawal-dialog.component';
import { MemberAlertViewEffects } from './components/member-alert/effects';
import { MemberAlertViewComponent } from './components/member-alert/member-alert-view.component';
import { PensionChangeScheduleComponent } from './components/pension-change-schedule/pension-change-schedule.component';
import { PensionChangePaymentScheduleEffects } from './components/pension-change-schedule/effects';
import { PensionChangeScheduleRequestsComponent } from './components/pension-change-schedule-requests/pension-change-schedule-requests.component';
import { PensionChangePaymentScheduleRequestsEffects } from './components/pension-change-schedule-requests/effects';
import { AccountFeesComponent } from './components/account-fees/account-fees.component';
import { AccountFeesEffects } from './components/account-fees/effects';
import { InvestmentSwitchHistoryEffects } from './components/investment-switch-history/effects';
import { InvestmentSwitchHistoryComponent } from './components/investment-switch-history/investment-switch-history.component';
import { InvestmentSwitchHistoryCardComponent } from './components/investment-switch-history/investment-switch-history-card/investment-switch-history-card.component';
import { StartAPensionStartComponent } from './components/start-a-pension/start-a-pension-start/start-a-pension-start.component';
import { StartAPensionStartComponentEffects } from './components/start-a-pension/start-a-pension-start/effects';
import { StartAPensionFormComponentEffects } from './components/start-a-pension/start-a-pension-form/effects';
import { StartAPensionFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-form.component';
import { StartAPensionEligibilityFormComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-eligibility-form/effects';
import { StartAPensionEligibilityFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-eligibility-form/start-a-pension-eligibility-form.component';
import { StartAPensionStepper } from './components/start-a-pension/start-a-pension-form/start-a-pension-progress-stepper/start-a-pension-progress-stepper.component';
import { StartAPensionAccountDetailsFormComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-account-details-form/effects';
import { StartAPensionAccountDetailsFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-account-details-form/start-a-pension-account-details-form.component';
import { StartAPensionInvestmentOptionsComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-investment-options/start-a-pension-investment-options.component';
import { StartAPensionPaymentDetailsFormComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-payment-details-form/effects';
import { StartAPensionPaymentDetailsFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-payment-details-form/start-a-pension-payment-details-form.component';
import { StartAPensionBankDetailsFormComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-bank-details-form/effects';
import { StartAPensionBankDetailsFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-bank-details-form/start-a-pension-bank-details-form.component';
import { StartAPensionBeneficiaryFormComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-beneficiary-form/effects';
import { StartAPensionBeneficiaryFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-beneficiary-form/start-a-pension-beneficiary-form.component';
import { StartAPensionDocumentsFormComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-documents-form/start-a-pension-documents-form.component';
import { StartAPensionDocumentsFormComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-documents-form/effects';
import { StartAPensionReviewComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-review/start-a-pension-review.component';
import { StartAPensionReviewComponentEffects } from './components/start-a-pension/start-a-pension-form/start-a-pension-review/effects';
import { StartAPensionReviewDisplayComponent } from './components/start-a-pension/start-a-pension-form/start-a-pension-review/start-a-pension-review-display/start-a-pension-review-display.component';
import { StartAPensionUpdateSubmittedComponent } from './components/start-a-pension/start-a-pension-update-submitted/start-a-pension-update-submitted.component';
import { StartAPensionUpdateSubmittedComponentEffects } from './components/start-a-pension/start-a-pension-update-submitted/effects';
import { InsuranceUpdateComponentEffects } from './components/insurance-update/effects';
import { InsuranceUpdateComponent } from './components/insurance-update/insurance-update.component';
import { InsuranceUpdateHistoryComponent } from './components/insurance-update-history/insurance-update-history.component';
import { InsuranceUpdateHistoryComponentEffects } from './components/insurance-update-history/effects';
import { InsuranceUpdateHistoryCardComponent } from './components/insurance-update-history/insurance-update-history-card/insurance-update-history-card.component';

let declarations = [
  TenantConfigPipe,
  TextTransformPipe,
  NoInsuranceComponent,
  MessagePipe,
  FeatureTogglePipe,
  ConvertNanPipe,
  AnimateCssDirective,
  FadeInConcatDirective,
  FundComponentLoaderDirective,
  FeatureToggleDirective,
  FeatureToggleGlobalDirective,
  AppButtonDirective,
  AppRadioDirective,
  MessageDirective,
  ScreenWidthDirective,
  PermissionDirective,
  InputScrollDirective,
  InputAddressScrollDirective,
  InputDateScrollDirective,
  AppComponentWrapperComponent,
  BeneficiariesViewComponent,
  BPAYDetailsComponent,
  AccountUnderlyingViewComponent,
  AccountUnderlyingInvestmentSummaryViewComponent,
  MemberViewComponent,
  InvestmentEditComponent,
  InvestmentOptionsComponent,
  UserTopDropdownComponent,
  MemberAccountDropdownComponent,
  MemberViewOverviewComponent,
  ConsolidateSuperComponent,
  MemberEditComponent,
  BeneficiariesEditComponent,
  CashTransactionsComponent,
  HistoricalChartViewComponent,
  InsuranceQuestionnaireComponent,
  InsuranceQuestionnaireStartComponent,
  InsurancePreQuestionnaireComponent,
  InsuranceDetailsComponent,
  SwitchInvestmentsEditComponent,
  RollinEditComponent,
  ContributionsLimitsComponent,
  SuperannuationProviderSearchComponent,
  PersonalContributionEditComponent,
  UnitPricesViewComponent,
  MemberDocumentViewerViewComponent,
  NotificationDropdownComponent,
  MemberPensionViewComponent,
  AccessYourSuperStartComponent,
  AccessYourSuperFormComponent,
  AccessYourSuperTypeSelectionComponent,
  AvailableAccountBalanceComponent,
  AccessYourSuperFormComponent,
  AccessYourSuperLastEmployerComponent,
  AccessYourSuperBankDetailsComponent,
  AccessYourSuperDocumentsComponent,
  AccessYourSuperRetirementComponent,
  AccessYourSuperCeasedWorkSixtyComponent,
  AccessYourSuperPermanentRetirementPreservationComponent,
  AccessYourSuperCeasedPaidEmploymentComponent,
  AccessYourSuperPensionCommutationsComponent,
  AccessYourSuperDrawdownComponent,
  AccessYourSuperReviewComponent,
  RecentEmploymentDetailsComponent,
  LimitedServiceBannerComponent,
  AccessYourSuperTerminalMedicalConditionComponent,
  AccessYourSuperSevereFinancialHardshipComponent,
  AccessYourSuperCompassionateGroundsComponent,
  AccessYourSuperUnrestrictedNonPreservedComponent,
  AccountSummaryComponent,
  DefinedBenefitAccountSummaryComponent,
  AccountBalWithoutGraphComponent,
  AccessYourSuperKiwiSaverComponent,
  AccessYourSuperStepper,
  InvestmentEditHoverComponent,
  InvestmentSimpleViewComponent,
  InvestmentComplexViewComponent,
  ElevatedAccessBannerComponent,
  ChatViewComponent,
  AreYouStillThereDialog,
  AccessYourSuperUpdateSubmittedComponent,
  AccessYourSuperReviewDisplayComponent,
  NoticeOfIntentTaxDeductionContainerComponent,
  NoticeOfIntentTaxDeductionFormComponent,
  SfhFinancialDetailsV1Component,
  SfhFinancialDetailsReviewV1Component,
  NoiPendingWithdrawalDialog,
  MemberAlertViewComponent,
  AccountFeesComponent,
  PensionChangeScheduleComponent,
  PensionChangeScheduleRequestsComponent,
  InvestmentSwitchHistoryComponent,
  InvestmentSwitchHistoryCardComponent,
  StartAPensionStartComponent,
  StartAPensionFormComponent,
  StartAPensionEligibilityFormComponent,
  StartAPensionStepper,
  StartAPensionAccountDetailsFormComponent,
  StartAPensionInvestmentOptionsComponent,
  StartAPensionPaymentDetailsFormComponent,
  StartAPensionBankDetailsFormComponent,
  StartAPensionBeneficiaryFormComponent,
  StartAPensionDocumentsFormComponent,
  StartAPensionReviewComponent,
  StartAPensionReviewDisplayComponent,
  StartAPensionUpdateSubmittedComponent,
  InsuranceUpdateComponent,
  InsuranceUpdateHistoryComponent,
  InsuranceUpdateHistoryCardComponent
];

let exports = [
  NoInsuranceComponent,
  TenantConfigPipe,
  TextTransformPipe,
  MessagePipe,
  FeatureTogglePipe,
  ConvertNanPipe,
  AnimateCssDirective,
  FundComponentLoaderDirective,
  FadeInConcatDirective,
  FeatureToggleDirective,
  FeatureToggleGlobalDirective,
  AppButtonDirective,
  AppRadioDirective,
  MessageDirective,
  PermissionDirective,
  ScreenWidthDirective,
  InputScrollDirective,
  InputAddressScrollDirective,
  InputDateScrollDirective,
  AppComponentWrapperComponent,
  BeneficiariesViewComponent,
  BPAYDetailsComponent,
  AccountUnderlyingViewComponent,
  AccountUnderlyingInvestmentSummaryViewComponent,
  MemberViewComponent,
  InvestmentEditComponent,
  InvestmentOptionsComponent,
  UserTopDropdownComponent,
  MemberAccountDropdownComponent,
  MemberViewOverviewComponent,
  ConsolidateSuperComponent,
  MemberEditComponent,
  BeneficiariesEditComponent,
  CashTransactionsComponent,
  HistoricalChartViewComponent,
  InsuranceQuestionnaireComponent,
  InsuranceQuestionnaireStartComponent,
  InsurancePreQuestionnaireComponent,
  InsuranceDetailsComponent,
  SwitchInvestmentsEditComponent,
  RollinEditComponent,
  ContributionsLimitsComponent,
  PersonalContributionEditComponent,
  UnitPricesViewComponent,
  MemberDocumentViewerViewComponent,
  NotificationDropdownComponent,
  MemberPensionViewComponent,
  AccessYourSuperStartComponent,
  AccessYourSuperFormComponent,
  AccessYourSuperTypeSelectionComponent,
  AvailableAccountBalanceComponent,
  AccessYourSuperFormComponent,
  AccessYourSuperLastEmployerComponent,
  AccessYourSuperBankDetailsComponent,
  AccessYourSuperDocumentsComponent,
  AccessYourSuperRetirementComponent,
  AccessYourSuperCeasedWorkSixtyComponent,
  AccessYourSuperPermanentRetirementPreservationComponent,
  AccessYourSuperCeasedPaidEmploymentComponent,
  AccessYourSuperPensionCommutationsComponent,
  AccessYourSuperDrawdownComponent,
  AccessYourSuperReviewComponent,
  RecentEmploymentDetailsComponent,
  AccountSummaryComponent,
  AccessYourSuperTerminalMedicalConditionComponent,
  AccessYourSuperSevereFinancialHardshipComponent,
  AccessYourSuperCompassionateGroundsComponent,
  AccessYourSuperUnrestrictedNonPreservedComponent,
  LimitedServiceBannerComponent,
  DefinedBenefitAccountSummaryComponent,
  AccountBalWithoutGraphComponent,
  AccessYourSuperKiwiSaverComponent,
  AccessYourSuperStepper,
  InvestmentEditHoverComponent,
  InvestmentSimpleViewComponent,
  InvestmentComplexViewComponent,
  ElevatedAccessBannerComponent,
  ChatViewComponent,
  AccessYourSuperStartComponent,
  AreYouStillThereDialog,
  AccessYourSuperUpdateSubmittedComponent,
  AccessYourSuperReviewDisplayComponent,
  NoticeOfIntentTaxDeductionContainerComponent,
  NoticeOfIntentTaxDeductionFormComponent,
  SfhFinancialDetailsV1Component,
  SfhFinancialDetailsReviewV1Component,
  NoiPendingWithdrawalDialog,
  MemberAlertViewComponent,
  AccountFeesComponent,
  PensionChangeScheduleComponent,
  PensionChangeScheduleRequestsComponent,
  InvestmentSwitchHistoryComponent,
  InvestmentSwitchHistoryCardComponent,
  StartAPensionStartComponent,
  StartAPensionFormComponent,
  StartAPensionEligibilityFormComponent,
  StartAPensionStepper,
  StartAPensionAccountDetailsFormComponent,
  StartAPensionInvestmentOptionsComponent,
  StartAPensionPaymentDetailsFormComponent,
  StartAPensionBankDetailsFormComponent,
  StartAPensionBeneficiaryFormComponent,
  StartAPensionDocumentsFormComponent,
  StartAPensionReviewComponent,
  StartAPensionReviewDisplayComponent,
  StartAPensionUpdateSubmittedComponent,
  InsuranceUpdateComponent,
  InsuranceUpdateHistoryComponent,
  InsuranceUpdateHistoryCardComponent
]

@NgModule({
  declarations: declarations,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    NgApexchartsModule,
    HttpClientModule,
    PdfJsViewerModule,
    LetModule, PushModule,
    IfaaUiComponentsModule,
    MemberPortalSharedRoutingModule,
    MaterialModule,
    NgrxFormsModule,
    StoreModule.forFeature(featureKey, reducers, { metaReducers: [localStorageSyncMetaReducer] }),
    EffectsModule.forFeature([
      MemberEffects,
      MemberViewOverviewEffects,
      AccountUnderlyingViewEffects,
      AccountUnderlyingInvestmentSummaryViewEffects,
      BeneficiariesViewEffects,
      BPAYDetailsEffects,
      EditMemberEffects,
      EditInvestmentEffects,
      BeneficiariesEditEffects,
      CashTransactionsEffects,
      MemberAccountDropdownEffects,
      HistoricalChartViewEffects,
      InsuranceQuestionnaireEffects,
      InsuranceQuestionnaireStartEffects,
      InvestmentOptionsEffects,
      InsuranceDetailsEffects,
      SwitchInvestmentsEffects,
      RollInEditEffects,
      ContributionsLimitsEffects,
      SuperannuationProviderSearchEffects,
      PersonalContributionEditEffects,
      ConsolidateSuperEffects,
      UnitPricesViewEffects,
      DocumentViewerEffects,
      NotificationDropdownEffects,
      MemberPensionEffects,
      AccessYourSuperStartComponentEffects,
      AccessYourSuperTypeSelectionComponentEffects,
      AccessYourSuperFormComponentEffects,
      AccessYourSuperLastEmployerComponentEffects,
      AccessYourSuperBankDetailsComponentEffects,
      AccessYourSuperDocumentsComponentEffects,
      AccessYourSuperRetirementComponentEffects,
      AccessYourSuperCeasedWorkSixtyComponentEffects,
      AccessYourSuperPermanentRetirementPreservationComponentEffects,
      AccessYourSuperCeasedPaidEmploymentComponentEffects,
      AccessYourSuperPensionCommutationsComponentEffects,
      AccessYourSuperReviewComponentEffects,
      RecentEmploymentDetailsEffects,
      AccountSummaryEffects,
      AccessYourSuperTerminalMedicalConditionComponentEffects,
      AccessYourSuperSevereFinancialHardshipComponentEffects,
      AccessYourSuperCompassionateGroundsComponentEffects,
      AccessYourSuperUnrestrictedNonPreservedComponentEffects,
      AccessYourSuperKiwiSaverComponentEffects,
      ChatViewEffects,
      AccessYourSuperUpdateSubmittedComponentEffects,
      NoticeOfIntentTaxDeductionContainerComponentEffects,
      NoticeOfIntentTaxDeductionFormComponentEffects,
      MemberAlertViewEffects,
      AccountFeesEffects,
      PensionChangePaymentScheduleEffects,
      PensionChangePaymentScheduleRequestsEffects,
      InvestmentSwitchHistoryEffects,
      StartAPensionStartComponentEffects,
      StartAPensionFormComponentEffects,
      StartAPensionEligibilityFormComponentEffects,
      StartAPensionAccountDetailsFormComponentEffects,
      StartAPensionPaymentDetailsFormComponentEffects,
      StartAPensionBankDetailsFormComponentEffects,
      StartAPensionBeneficiaryFormComponentEffects,
      StartAPensionDocumentsFormComponentEffects,
      StartAPensionReviewComponentEffects,
      StartAPensionUpdateSubmittedComponentEffects,
      InsuranceUpdateComponentEffects,
      InsuranceUpdateHistoryComponentEffects
    ]),
    TranslateModule.forChild(),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 400,
      backgroundPadding: 0,
      animation: true
    })
  ],
  exports: exports,
  providers: [

  ],
  entryComponents: [
    AreYouStillThereDialog,
    NoiPendingWithdrawalDialog
  ]
})
export class MemberPortalSharedModule { }
