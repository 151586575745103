import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { commomState_LoggedinInfo, commonState_AccountDataRetrieved, commonState_SystemConfig_AccountFeatureToggles, commonState_SystemConfig_SiteError } from '../store/common/common.selectors';
import { memberAccountDropdown_SelectedAccount } from '../modules/shared/components/member-account-dropdown/selectors';
import { RequestAction } from '../modules/shared/components/member-account-dropdown/actions';
import { AccountDataRetrievedAction, AccountFeatureTogglesRequestAction, IsLoggedInRequestAction } from '../store/common/common.actions';
import { distinctUntilChanged, map, skipWhile, tap } from 'rxjs/operators';

@Injectable()
export class AccountDataGuardService implements CanActivate {

    featuretoggle$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    siteError$ = this.store.pipe(select(commonState_SystemConfig_SiteError));
    accountDataRetrieved$ = this.store.pipe(select(commonState_AccountDataRetrieved));
    isLoggedIn$ = this.store.pipe(select(commomState_LoggedinInfo));

    constructor(public store: Store<AppState>,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return combineLatest([this.featuretoggle$, this.selectedAccount$, this.siteError$, this.accountDataRetrieved$, this.isLoggedIn$])
            .pipe(
                distinctUntilChanged((x, y) => {
                    if (JSON.stringify(x[1]) !== JSON.stringify(y[1]) ||
                        JSON.stringify(x[0]) !== JSON.stringify(y[0]) ||
                        JSON.stringify(x[3]) !== JSON.stringify(y[3]) ||
                        x[4].memberNumber !== y[4].memberNumber)
                        return false;

                    return true;
                }),
                tap(x => {
                    // If its a site wide error, dont retry any api calls (avoid infinite loop)
                    if (x[2]) {
                        return;
                    }
                    if (!x[4].memberNumber) {
                        this.store.dispatch(IsLoggedInRequestAction());
                    }
                    // if the account data hasnt been retrieved on initial load
                    // send request and set flag so this doesnt get triggered again on navigation
                    if (!x[3] && x[4].memberNumber) {
                        this.store.dispatch(RequestAction())
                        this.store.dispatch(AccountDataRetrievedAction());
                    }
                    else if (x[1] && !x[0]) {
                        this.store.dispatch(AccountFeatureTogglesRequestAction({ accountId: x[1].accountId }))
                    }
                }),
                skipWhile(x => {
                    // If its a site wide error, continue through to return false
                    if (x[2])
                        return false;

                    return !x[0] || !x[1];
                }),
                map(x => {
                    // If its a site wide error, return false
                    if (x[2])
                        return false;
                    return true;
                })
            );
    }
}
